.sidebar-open {
    width: 13vw;
    min-width: 250px;
    padding: 0 15px;
}

@media screen and (min-width: 2560px) {
    .sidebar-open {
        width: 18vw;
        padding: 0 1rem;
    }
}

@media screen and (max-width: 882px) {
    .sidebar-open {
        min-width: 165px;
    }
}

.sidebar {
    transition: width 0.5s ease, min-width 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.sidebar-raw-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.sidebar-collapsed {
    width: 5vw;
    min-width: 75px;
}

.sidebar-collapse-container {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
}

.sidebar-collapse-btn {
    cursor: pointer;
}

.sidebar-year {
    color: var(--mpac-main-100);
    cursor: pointer;
    font-weight: bold;
    justify-content: center;
    display: flex;
    vertical-align: middle;
    margin: 1.5em 0;
}

.sidebar-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.sidebar-footer-info-container {
    display: flex;
    justify-content: center;
}

.sidebar-logo-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px var(--mpac-light-grey-border) solid;
    align-items: center;
}

.sidebar-logo-image-container img {
    width: 20%;
    height: 60%;
    min-width: 60px;
    max-width: 100%;
}

@media screen and (min-width: 2560px) {
    .sidebar-logo-image-container img {
        width: 50%;
        height: 50%;
    }
}

.sidebar-mpac-info {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sidebar-mpac-info div {
    margin: 0;
    font-size: 0.75rem;
}

.sidebar-mpac-info a {
    margin: 0;
    font-size: 0.75rem;
}

.sidebar-mpac-info-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}