.gev-table td {
  vertical-align: top;
}

.gev-table .index {
  padding-right: 0.25rem;
}

.gev-dialog-max-width {
  max-width: 30em !important;
}

.gev-modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gev-input-tr {
  padding-top: 0rem;
  padding-bottom: 1rem;
}

.gev-input-label {
  width: 100%;
  padding-bottom: 0.5rem;
}

.gev-input-100 {
  width: 100%;
  box-sizing: border-box;
}

.gev-yesno {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gev-crop-text {
  text-wrap: nowrap;
}

.gev-space-between {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.padding-bottom-percrop {
  padding-bottom: 0.5rem;
}

.percentageOfThroughput td {
  text-align: center;
}

.gev-table input {
  margin: 0;
  border-radius: 5px;
}

.gev-table textarea {
  background-color: transparent;
  border-radius: 5px;
}

.gev-table .input-error-message {
  margin: 0;
  font-size: 12px;
}

.gev-throughput-input {
  margin: 0;
}

.gev-div-dateOfChange {
  padding-top: 10px;
}

.gev-dateOfChange {
  border-radius: 5px;
}

.gev-confirm-dialog-header {
  border-bottom: 0 !important;
}

.gev-confirm-dialog-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}